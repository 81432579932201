/* eslint-disable no-unused-expressions */
/* eslint-disable import/prefer-default-export */

function cleanSvgs(el) {
  const svgs = [...el?.querySelectorAll('svg')];

  svgs.forEach((svg) => {
    svg?.removeAttribute('width');
    svg?.removeAttribute('height');
  });
}

export const cleanSvgDirective = {
  beforeMount: cleanSvgs,
  updated: cleanSvgs,
};
