<template>
  <section v-clean-svg class="subpage-grid constrain pb-12">
    <div v-if="payload.titelanzeigen.length" class="text-center mb-16">
      <h2 v-text="payload.title" />
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div
        v-for="(page, i) in payload.subpages"
        :key="page.id"
        v-view
        :class="{ 'in-left': i % 2 === 0, 'in-right': i % 2 === 1 }"
        class="animate bg-green-light"
      >
        <Go
          :to="page.link"
          class="page-link"
        >
          <Image
            :src="page.icon"
            :style="{
              '--svg-height': '3.07142857rem',
              height: 'var(--svg-height)',
              width: 'var(--svg-height)',
            }"
            class="flex items-center justify-center mb-2 sm:mb-0 sm:mr-4"
          />
          <div class="w-full text-center sm:text-left font-sans" v-text="page.title" />
          <Arrow class="transform -rotate-90 w-4" />
        </Go>
      </div>
    </div>
  </section>
</template>

<script>
import Arrow from '@/assets/images/arrow.svg';
import Image from '@/components/utils/Image.vue';
import { cleanSvgDirective } from '@/directives/cleanSvg';

export default {
  components: { Image, Arrow },
  directives: { cleanSvg: cleanSvgDirective },
  props: { payload: { type: Object, default: Object } },
};
</script>

<style lang="scss" scoped>
.page-link {
  @apply flex flex-col items-center justify-center  no-underline px-4 py-4;

  @screen sm {
    @apply flex-row justify-between px-8;
  }
}
</style>
